.headerWorkshop {
    position: relative;
    width: 100%;
    height: 80vh;
    margin-top: 140px;
}

.headerWorkshop img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition-delay: 1100ms;
    
}

.headerWorkshop p{
    color: white;
    position: absolute;
    font-style: italic;
}

.firstTitle {
    right:100px;
    bottom: 160px;
    font-size: 40px;
    font-weight: 600;
    transition-delay: 1700ms;
}

.secondTitle {
    right:100px;
    bottom: 100px;
    font-size: 20px;
    font-weight: 300;
    transition-delay: 2300ms;
}

.workshopCards {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    justify-content: center;
    gap: 20px; 
    padding: 20px;
    margin: 50px 0;
    margin-left: 70px; 
    max-width: calc(100% - 100px); 
    box-sizing: border-box;
}


.workshopCards :nth-child(1) {
    transition-delay: 800ms;
}

.workshopCards :nth-child(2) {
    transition-delay: 1000ms;
}

.workshopCards :nth-child(3) {
    transition-delay: 1200ms;
}

.workshopCards :nth-child(4) {
    transition-delay: 800ms;
}

.workshopCards :nth-child(5) {
    transition-delay: 1000ms;
}

.workshopCards :nth-child(6 ) {
    transition-delay: 1200ms;
}

.card {
    height: 400px;
    width: 100%;
    max-width: 400px; 
    border-radius: 15px;
    margin-bottom: 50px;
    box-shadow: 4px 4px 8px 4px  rgba(0, 0, 0, 0.1);
    overflow: hidden;
    
}

.card img {
    height: 300px;
    width: 100%;
    object-fit: cover;
    border-radius: 20px 20px 0px 0px;
}

.title {
    padding-top: 20px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: brown;
}

.description {
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    color: brown;
}



@media (max-width:912px) {
    .headerWorkshop {
        height: 80vh
        
    }

    .headerWorkshop img {
        object-position: 18% 50%;
    }

    .headerWorkshop p {
       width: 70%;
       height: auto;
       align-items: center;
       justify-content: center;
    }

    .firstTitle {
        font-size: 20px;
    }

    .secondTitle {
        font-size: 15px;
    }

    .workshopCards {
        margin: 50px auto;
        display: block;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
    }

    .card {
        width: 100%;
        height: auto;
    }

    .card p {
        padding: 10px;
    }
    
    
}

@media (max-width: 375px) and (max-height: 667px) {
    

    .headerWorkshop img {
        object-position: center;
    }

    .workshopCards {
        height: auto;
    }

    
}