.slideContainer {
    height: 700px;
    width: 100%;
    animation: fadeIn 1s ease;
    margin: 0;
    justify-content: center; 
    align-items: center; 
    position: relative;
    overflow: hidden; 
}

.slideContainer iframe {
    height: 900px;
    width: 100%;
    border: 0;
    pointer-events: none;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.slideTextContainer {
    position: absolute;
    top: 66.6%;
    left: 70%; 
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.slideText {
    color: white; 
    font-size: 45px; 
    text-align: center; 
    width: 150%;
    text-decoration: none;
    font-weight: 700;
    transition-delay: 1.5s;
}

@keyframes slideInFromLeft {
    from {
        transform: translateX(-100px); 
        opacity: 0; 
    }
    to {
        transform: translateX(0); 
        opacity: 1; }
}

.slideButton {
    color: #fefefe; 
    background-color: #830e00;
    font-size: 20px; 
    font-weight: 700;
    text-align: center; 
    width: 100%;
    height: auto;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    opacity: 0;
    box-shadow: none;
    outline: none;
    border: none;
    position: relative; 
    animation: slideInFromLeft 1s ease forwards; 
    animation-delay: 2s; 
}

@media (max-width: 912px) {
    .slideContainer iframe {
        margin-top: 60px;
        height: 650px;
        width: 220%;
        overflow: hidden;
        transform: translateX(-50%) translateY(-50%);
        left: 50%;
        top: 50%;
        position: absolute;
    }

    .slideContainer {
        margin-top: 60px;
        height: 590px;
        position: relative;
    }

    .slideTextContainer {
        display: none; 
    }
}