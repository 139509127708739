.servicesContainer {
    width: 100%;
    height: 100%;
    margin-top: 150px;
}

.servicesHeader {
    position: relative;
    display: flex;
    width: auto;
    height: 90vh;
    width: 100%;
    
}

.servicesHeader img {
    width: 100%;
    transition-delay: 1s;
}

.servicesHeader h1 {
    position: absolute;
    color: white;
    top: 15%;
    right: 5%;
    width: 50%;
    text-align: end;
    transition-delay: 1.5s;
}

.serviceCardContainer {
    display: flex;
    width: 100%;
    height: auto;
    margin-bottom: 50px;
}

.servicesCard {
    width: 50%;
    color: rgb(10, 16, 99);
    height: 80vh;
    background-color: #f4f4f4 ;
}

.servicesCard p {
    margin-bottom: 50px;
    font-size: 40px;
    font-weight: 600;
    padding-top: 80px;
    padding-left: 100px;
    transition-delay: 1200ms;
}

.servicesCard ul {
    padding-left: 100px;
}

.servicesCard li {
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: 500;
}

.servicesCard ul :nth-child(1) {
    transition-delay: 1700ms;
}

.servicesCard ul :nth-child(2) {
    transition-delay: 1900ms;
}

.servicesCard ul :nth-child(3) {
    transition-delay: 2100ms;
}

.servicesCard ul :nth-child(4) {
    transition-delay: 2300ms;
}

.servicesCard ul :nth-child(5) {
    transition-delay: 2500ms;
}

.serviceCardImage {
    position: relative;
    width: 50%;
    height: 80vh;
}

.serviceCardImage img{
    width: 100%;
    height: 100%;
}

.serviceCardImage button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    height: 100px;
    z-index: 2;
}

.serviceCardImage button:last-of-type {
    right: 5px;
}

.productService {
    margin-top: 50px;
    height: auto; 
    width: auto;
    text-align: center; 
    color: #931919;
    margin-bottom: 50px;
}

.titleProducts {
    margin-bottom: 40px;
    font-size: 30px;
    font-weight: 500;
}

.imageProducts {
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageProducts img {
    height: 200px;
    width: 300px;
    margin: 5px; 
    cursor: pointer;
}  

.arrowLeft {
    color: azure;
    position: absolute;
    right: 100%;
     bottom: 50%;
     background-color: transparent;
     width: 5%;
     height: 10%;
     font-size: 30px;
     border: none;
     cursor: pointer;
}

.arrowRight {
    color: azure;
    position: absolute;
    left: 100%;
    bottom:50%;
    background-color: transparent;
    width: 5%;
    height: 10%;
    font-size: 30px;
    border: none;
    cursor: pointer;
}


@media (max-width:912px) {

    .servicesHeader {
        width: 100%;
        height: 70vh;
        overflow: hidden;
        
    }

    .servicesHeader h1 {
        font-size: 15px;
        width: 50%;
        top: 60%;
    }

    .servicesHeader img {
        margin-top: 30px;
        left:40%;
        width: 180%;
        height: 70vh;
    }

    .serviceCardContainer {
        display: flex;
        flex-direction: column;
    }

    .servicesCard {
        width: 100%;
        padding-bottom: 40px;
    }

    .servicesCard p{
        width: 80%;
        font-size: 30px;
        margin-bottom: 40px;
        margin-top: 0;
    }

    .servicesCard li{
        font-size: 15px;
        width: 80%;
    }

    
    .serviceCardImage {
        height: auto;
        width: auto;
    }

    .productService{
        height: auto;
    }

    .titleProducts {
        font-size: 20px;
        margin: 60px 20px 50px 40px;
        width: 80%;
    }
}

 