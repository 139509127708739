.navbar {
    background-color:white;
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    display: flex;
    flex-direction: column;
}

.navbar-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: white;
}

.logo {
    height: 80px;
    margin-left: 100px;

}

.headerContact {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0 100px 0 0;
}

.headerContact li {
    margin: 0 10px;
    font-size: 15px;
}

#check {
    display: none;
}

.icons {
    display: none;
}

.NavPages {
    background-color: rgb(4, 4, 53);
    transition-delay: 1.5s;
}

.NavPages ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-weight: 700;
}

.NavPages li {
    margin: 10px 40px;
    font-size: 14px;
}

.NavPages li p {
    margin: 0;
    padding: 5px;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
    
}

.NavPages a {
    text-decoration: none;
}


.NavPages img {
    display: none;
}

.hovered {
    background-color: rgb(29, 29, 103);
}

.animate-left {
    opacity: 0;
    transform: translateX(100px); 
    transition: opacity 1s ease-out 1s, transform 1s ease-out 1s;
}

.animate-on-scroll {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease-out, transform 1s ease-out;
  }

@media (max-width: 768px) {
    .navbar {
        width: 100%;
    }

    .navbar-top {
        flex-direction: column-reverse;
        background-color: rgb(4, 4, 53);
    }

    .navbar-top img {
        display: none;
    }

    .headerContact {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 10px;
        color: white;
        height: 80px;
        margin: 0;
    }

    .headerContact li {
        font-size: 12px;
        margin-top: 10px;
    }

    .logo {
        margin-left: 10px;
    }


    .NavPages {
        background-color: white;
        display: flex;
        justify-content: space-between;
    }

    .NavPages img {
        display: flex;
    }

    .NavPages ul {
        flex-direction: column;
    }


    .icons {
        color: #850e00;
        display: flex;
        margin: 5%;
    }

    .NavPageList {
        top:100%;
        position: absolute;
        width: 100%;
        background-color: rgb(4, 4, 53);
        height: 0;
        overflow: hidden;
        transition: height 0.5s ease-in-out;
    }

    #check:checked ~ .NavPageList {
        height: 300px;
    }
}
