 .App {
    margin:-10px;
    padding: 0;
    overflow-x: hidden
    
}



.inBetweenText {
    background-color: rgb(4, 4, 53);
    margin-top: -20px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    font-size: 30px;
    width: 100%;
    font-weight: bold;
}

.inBetweenText p {
    position: relative;
}

.inBetweenText p::after {
    content: "";
    position: absolute;
    left: 250px;
    bottom: -25px; 
    width: 25%; 
    height: 3px; 
    background-color: #f46016;
    left: 50%;
    transform: translateX(-50%);
}

.animate-on-scroll {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  

.animate-right {
    opacity: 0;
    transform: translateX(-100px); 
    transition: opacity 0.5s ease-out 0.5s, transform 1s ease-out 0.5s;
  }
  

  .animate-left {
    opacity: 0;
    transform: translateX(100px); 
    transition: opacity 0.5s ease-out 0.5s, transform 1s ease-out 0.5s;
  }

  .animate-up {
        opacity: 0;
        transform: translateY(20px); 
        transition: opacity 0.5s ease-out 0.5s, transform 0.5s ease-out 0.5s;
  }
  

  .animate {
    opacity: 1;
    transform: translateX(0);
  }
  

  .imageRowContainer {
    height: auto;
    width: 100%;
    justify-content: center;
    margin-bottom: 50px;
    max-width: 100%;
    

    
}

.imageRowTitle {
    height: 25vh;
    display: flex;
    align-items: center;
    padding: 70px 140px;
    font-size: 30px;
    font-weight: 600;
    color: #850e00;
    background-color: rgb(239, 239, 239);
}

.imageRow {
    width: 100%;
    display: flex;
    margin: 0;
    flex-wrap: wrap;
    overflow: hidden;

}

.imageContainer {
    position: relative;
    width: calc(25%);
    box-sizing: border-box; 
}

.imageContainer img {
    height: 40vh;
    width: 100%
}


.imageContainer:hover {
    transform: scale(1.05);
    z-index: 10;
}

.imageText {
    position: absolute;
    bottom: -10px; 
    color: white;
    padding: 60px 40px;
    text-align: center;
    width: 100%;
    font-size: 21px;
    font-weight: 600;
    text-shadow: 1px 1px rgb(0, 0, 0);
}

.imageText2 {
    position: absolute;
    bottom: 10px; 
    color: white;
    padding: 60px 40px;
    text-align: center;
    width: 100%;
    font-size: 21px;
    font-weight: 600;
    text-shadow: 1px 1px rgb(0, 0, 0);
}


.workPlaceDescription {
    height: 550px;
    display: flex;
    overflow: hidden;
}

.workPlaceDescription img {
    height: 120%;
    width: 100%;
}

.descriptionText {
    position: absolute;
    margin-top: 60px;
    height: 400px;
    background-color: #ffffff;
    width: 45%;
    border-radius: 0 2% 2% 0;

}

.descriptionText h1 {
    margin-top: 100px;
    margin-right: 50px;
    height: auto;
    margin-left: 150px;
    color: #931919;
    font-size: clamp(20px, 2.6vw, 40px);
    max-width: 100%; 
    word-wrap: break-word;
}

.descriptionText p {
    margin-top: 10px;
    margin-left: 150px;
    margin-right: 100px;
    font-weight: 400;
    text-decoration: none;
    font-size: 1vw;
    color: rgb(11, 11, 11);
    max-width: 100%; 
    word-wrap: break-word;
}

.descriptionText a {

    text-decoration: none;
    color: black;
}


.products {
    display: block;
    height: auto;
    width: auto;
    text-align: center; 
    margin-bottom: 60px;
}

.titleProducts {
    margin-bottom: 50px;
    font-size: 30px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.imageProducts {
    margin-top: 5px; 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; }

.imageProducts img {
    height: 200px;
    width: auto;
    margin: 5px; 
    cursor: pointer;
}

.partners {
    height: 400px;
    width: 100%;
    background-color: rgb(4, 4, 53);
    display: flex;
    justify-content: center;
    color: white;
}

.lightbox {
    position: fixed;
    margin-left: 25%;
    margin-top: 2%;
    max-width: 80%;
    max-height: 100%;
    z-index: 1001; 
    background-color: transparent; 
  }
  

  .lightbox img {
    max-width: 100%; 
    max-height: 80vh;
    font-display: flex;
    align-items: center;
    border: black;
    
  }
  
  .lightbox-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75); 
    z-index: 1000; 
    cursor: pointer; 
  }

.arrowLeft {
        color: azure;
        position: absolute;
        bottom: 50%;
         background-color: transparent;
         width: 5%;
         height: 10%;
         font-size: 30px;
         border: none;
         cursor: pointer;
    }

.arrowRight {
        color: azure;
        position: absolute;
        left: 100%;
        bottom: 50%;
        background-color: transparent;
        width: 5%;
        height: 10%;
        font-size: 30px;
        border: none;
        cursor: pointer;
    }


@media (max-width: 1024px) {
    
    .imageRowTitle {
        font-size: 20px;
        width: 100%;
    }

    .imageRowTitle p {
        width: 100%;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .imageProducts {
        flex-direction: column;
        align-items: center;
    }

    .imageProducts img {
        width: 80%;
    }

    .partners h1 {
        font-size: 18px;
        margin: 20px 0;
    }

    .descriptionText h1{
        margin-top: 50px;
        margin-left: 30px;
        width: auto;
        font-size: 35px;
    }

    .descriptionText p{
        margin-right: 10px;
        margin-left: 30px;
        font-size: 15px;
        width: auto;
    }

    .descriptionText {
        width: auto;
        margin-left: 50px;
        margin-right: 50px;
        border-radius: 2%;
    }

    .inBetweenText {
        width: 100%;
        height: 130px;
        font-size: 10px;
        padding: 15px;
        
    }

    .inBetweenText p {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .inBetweenText p::after {
        content: "";
        position: absolute;
        bottom: -15px; 
        width: 30%; 
        height: 2px; 
        background-color: #f46016; 
        display: flex;
        align-items: start;
    }

    .imageRow {
        flex-direction: column; 
        align-items: center;
    }


    .imageContainer {
        width: 100%; 
        margin-bottom: 5px;
    }

    .imageContainer img {
        width: 100%;
        height: auto;
    }

    .lightbox {
        position: fixed;
        margin-left: 25%;
        margin-top: 2%;
        max-width: 80%;
        max-height: 100%;
        z-index: 1001; 
        background-color: transparent; 
        display: flex;
        align-items: center;
        justify-content: center;
        top: 25%;
        transform: translate(-18%, 0%);
      }
      
    
      .lightbox img {
        max-width: 100%; 
        max-height: 80vh;
        display: block;
        border: black;
        display: flex;
        align-items: center;
        justify-content: center;
        
      }
      
      .lightbox-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.75); 
        z-index: 1000; 
        cursor: pointer; 
      }
    

}