
.contactPageContainer {
    display: flex;
    width: 100%;
    height: 80vh; 
    margin-top: 150px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
}

.imageContactContainer {
    width: 50%;
    overflow: hidden;
    transition-delay: 1s;
}

.imageContactContainer img {
    width: 110%;
    height: 100%;
    border: 0;
    overflow: hidden;
    
}

.contactPageContact {
    margin-left: 50px;
    width: 50%;
    flex-direction: column;
    margin-top: 40px;
    
}


div li {
    list-style-type: none;
}


.contactPageData {
    flex: 0 0 40%; 
    padding: 20px;
    box-sizing: border-box;
    align-items: center;
    text-align: center;
}

.contactPageData ul :nth-child(1) {
    transition-delay: 1.5s;

}

.contactPageData ul :nth-child(2) {
    transition-delay: 1.5s;

}

.contactPageBottom {
    flex: 1 0 60%; 
    display: flex;
}

.contactPageBottom :nth-child(1) {
    transition-delay: 2s;

}

.contactPageBottom :nth-child(2) {
    transition-delay: 2.5s;

}

.formulario, .contactPageChannels {
    flex: 1; 
    margin-top: 30px;
    box-sizing: border-box;
    transition-delay: 2s;
} 

.titleContact {
    margin-bottom: 20px;
    color: #a31303;
}

.grupo {
    display: flex;
    width: 100%;   
}

.grupo input {
    flex: 1;
    margin-right: 10px;
}

.grupo input:last-child {
    margin-right: 0;
}

.grupo input, textarea {
    width: calc(50% - 10px); 
    height: 40px;
    padding: 10px;
    margin-bottom: 10px;
    font-family: 'Inter', sans-serif;
}

.grupo .comentarios {
    height: 150px;
    width: 100%;
    resize: none; 
}

.contactPageChannels {
    margin-top: 40px;
    font-weight: 500;
    margin-left: 60px;
}

.contactPageChannels li {
    margin-bottom: 10px;
    width: 200px;
}

.ubication {
    margin-top: 140px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.map {
    width: 100%;

}

.mapText {
    width: 100%;
    height: 180px;
    font-weight: 700;
    background-color: rgb(22, 22, 75);
    color: azure;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 50px;
}

.mapText p {
    margin-top: 15px;
    margin-bottom: 15px;
}

.mapText :nth-child(1) {
    transition-delay: 0.5s;

}

.mapText :nth-child(2) {
    transition-delay: 1s;

}

.map iframe {
    width: 100%; 
    height: 70vh;
}

.buttonContainer {
    display: flex;
    justify-content: center;
}

.buttonContainer button {
    width: 100%;
}

@media (max-width: 912px) {
    .contactPageContainer {
        display: block;
        flex-direction: column;
        width: 100%;
        height: auto;
    }

    .imageContactContainer {
        height: auto;
        width: 100%;
    }

    .imageContactContainer img {
        width: 100%;
    }

    .contactPageContact {
        width: 100%;
        height: auto;
        margin-top: 20px;
        margin-left: 0;
    }

    .contactPageBottom {
        display: block;
        width: 100%;
        margin-left: 0; 
        padding: 0 20px; 
    }

    .formulario input{
        margin-bottom: 10px;
        
    }

    .contactPageChannels {
        margin-top: 80px;
        margin-left: 0;
    }

    .mapText {
        margin-top: 150px;
    }

    .mapText p {
        margin-left: 20px;
        margin-right: 20px;
        font-size: 13px;
        align-items: center;
        justify-content: center;
    }

    .contactPageBottom :nth-child(2) {
        transition-delay: 1s;
    
    }

    .mapText :nth-child(1) {
        transition-delay: 0.5s;
    
    }
    
    .mapText :nth-child(2) {
        transition-delay: 0.5s;
    
    }
}