.footerContainer {
    background-color: rgba(239, 239, 239, 1);
    color: black;
    height: 300px;
    width: 100%;
    display: flex;
    padding-top: 20px;
}

.logoFooter {
    display: flex;
    align-items: center;
    margin-left: 130px;
}

.logoFooter img {
    align-items: center;
    margin-right: 100px;
    width: auto;
    height: 80px;
}

.siteContainer, .contactContain, .visitContainer {
    margin-top: 20px;
}

.siteContainer li {
    margin-top: 15px;
    font-weight: 600;
    text-decoration: none;
    margin-right: 70px;
}

.siteContainer a {
    text-decoration: none;
    color: inherit; 
    
}

.visitContainer li {
    margin-top: 15px;
    margin-right: 50px;
}

.contactContain li {
    margin-top: 15px;
}

.titleContainer {
    font-size: 20px;
    font-weight: 600;
}


@media (max-width: 912px) {
    .footerContainer {
        min-height: 1100px;
        flex-direction: column;
        justify-content: center;
    }

    .logoFooter {
        margin-top: -350px;
        margin-left: 50px;
        margin-bottom: 30px;
    }

    .siteContainer {
        margin-left: 30px;
        margin-bottom: 30px;
    }

    .visitContainer {
        margin-left: 30px;
        margin-bottom: 30px;
    }

    .contactContain {
        margin-left: 30px;
    }
    
}